<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <main class="lg:relative">
    <div class="mx-auto max-w-7xl w-full pb-20 text-center lg:text-left mt-12">
      <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
        <h1
          class="
            text-4xl
            tracking-tight
            font-extrabold
            text-gray-900
            sm:text-5xl
            md:text-6xl
            lg:text-5xl
            xl:text-6xl
          "
        >
          <span class="block xl:inline">We are here to</span>
          {{ " " }}
          <span class="block xl:inline">guide you all along</span>
        </h1>
        <p
          class="
            mt-3
            max-w-md
            mx-auto
            text-sm text-gray-500
            sm:text-lg
            md:mt-5
            md:max-w-3xl
          "
        >
          Whether you are buying or selling a residential property, we adopts a
          <span class="font-semibold">client focused approach</span> and will
          ensure your property transaction proceeds efficiently. Our specialist
          property lawyers will provide you with the expert advice and guidance
          that you need to ensure
          <span class="font-semibold"
            >your move is as easy and stress free as possible</span
          >.
        </p>
        <div class="mt-10 sm:flex sm:justify-center lg:justify-start">
          <div class="rounded-md shadow">
            <router-link
              to="/onboarding/step"
              class="
                w-full
                flex
                items-center
                justify-center
                px-8
                py-3
                border border-transparent
                text-base
                font-medium
                rounded-md
                text-white
                bg-light-blue-700
                hover:bg-light-blue-8
                00
                md:py-4
                md:text-lg
                md:px-10
              "
            >
              Start your application
            </router-link>
          </div>
        </div>
        <div class="mt-5 md:mt-10 border-t-2 border-gray-200">
          <p class="mt-5 mx-auto text-base text-gray-500 italic md:mt-10">
            “This was the third time I have used Ashley James to move home. Indy
            was superb from start to finish. His regular updates, organisation
            and was always contactable. Genuinely a faultless service. Thank you
            and I can’t recommend Ashley James enough... 10/10”
          </p>
          <figure class="flex space-x-4 mt-5">
            <img src="@/assets/img/Avatar.png" />
            <figcaption class="text-gray-700 font-medium">
              Marcia Hill, Digital Marketing Manager
            </figcaption>
          </figure>
          <img class="mt-5" src="@/assets/img/trustpilot-logo.svg" />
        </div>
      </div>
    </div>
    <div
      class="
        relative
        w-full
        h-64
        sm:h-72
        md:h-78
        lg:absolute
        lg:inset-y-0
        lg:right-0
        lg:w-1/2
        lg:h-2/3
      "
    >
      <img
        class="absolute inset-0 w-full h-full object-cover"
        src="@/assets/img/onboarding.svg"
        alt=""
      />
    </div>
  </main>
</template>

<script>
export default {};
</script>
